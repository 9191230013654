<template>
  <opportunity-filter></opportunity-filter>
</template>

<script>
import store from "../../store/store";
import OpportunityFilter from "./OpportunityFilter";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "OpportunityReport",
  mixins: [Mixin],
  components: {
    OpportunityFilter
  },
  data() {
    return {};
  },

  methods: {},
  mounted() {
    store.commit("showLoader", false); // Loader Off
  },
};
</script>

