<template>
  <CCard>
    <CCardHeader>
      <strong>Opportunity Chart</strong>
    </CCardHeader>
    <CCardBody>

      <CRow>
        <CCol sm="12" align="left">
          <div class="form-group">
            <label class="form__label" style="vertical-align: text-bottom">{{
              $lang.report.filter.date
              }}</label> &nbsp;
            <template>
              <date-range-picker v-model="dateRange"

                                 :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                 :showWeekNumbers="showWeekNumbers"
                                 :showDropdowns="showDropdowns"
                                 :autoApply="autoApply"
                                 :timePicker="timePicker"
                                 :timePicker24Hour="timePicker24Hour"
              >
                <!--    header slot-->
                <!--    input slot (new slot syntax)-->
                <template #input="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <!--    date slot-->
                <template #date="data">
                  <span class="small">{{ data.date | dateCell }}</span>
                </template>
                <!--    ranges (new slot syntax) -->
                <template #ranges="ranges">
                  <div class="ranges">
                    <ul>
                      <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                        <b>{{ name }}</b> <small class="text-muted">{{ range[0].toDateString() }} -
                        {{ range[1].toDateString() }}</small>
                      </li>
                    </ul>
                  </div>
                </template>
                <!--    footer slot-->
                <div slot="footer" slot-scope="data" class="slot">
                  <div>
                    <b class="text-black">Current Week (m/d/Y)</b> : {{ data.rangeText }}
                  </div>
                  <div style="margin-left: auto">
                    <a @click="data.clickApply" v-if="!data.in_selection" class="btn btn-primary btn-sm">Choose
                      Current</a>
                  </div>
                </div>
              </date-range-picker>
            </template>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.opportunity.filter.industry
              }}</label>
            <v-select
                :options="industryOptions"
                v-model="opportunity.industry"
                @option:selected="getSubIndustry"
            ></v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.opportunity.filter.subIndustry
              }}</label>
            <v-select
                :options="services"
                multiple="multiple"
                v-model="opportunity.services"
            ></v-select>
          </div>
        </CCol>


        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.opportunity.filter.skills
              }}</label>

            <v-select
                multiple="multiple"
                :options="skillsOptions"
                v-model="opportunity.skills"
            ></v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.report.filter.countries
              }}</label>
            <v-select
                :options="CountryOptions"
                v-model="opportunity.country"
                @option:selected="loadState"
            >
            </v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.report.filter.states
              }}</label>
            <v-select :options="StateOptions" v-model="opportunity.state">
            </v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
              $lang.report.filter.types
              }}</label>
            <v-select :options="typesOptions" v-model="opportunity.types">
            </v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label"> {{ $lang.report.filter.currency }}</label>
            <v-select
                :options="currencyOptions"
                v-model="opportunity.currency"
            >
            </v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{$lang.opportunity.filter.duration}}</label>

            <input
                type="text"
                :placeholder="$lang.opportunity.filter.duration"
                class="form-control"
                v-model="opportunity.duration"
            />

          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label"
            >{{ $lang.resource.form.experience }}
            </label>
            <v-select
                :options="experienceOptions"
                v-model="opportunity.experience"
            ></v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{ $lang.opportunity.filter.remoteWork }}</label>
            <v-select :options="remoteWorkOptions" v-model="opportunity.remoteWork"></v-select>
          </div>
        </CCol>
        <CCol sm="4">
          <div class="form-group">
            <label class="form__label">{{
              $lang.opportunity.filter.min_budget
              }}</label>
            <VueSimpleRangeSlider
                style="width: 300px"
                :min="0"
                :max="1000000"
                v-model="min"
            />
          </div>
        </CCol>
        <CCol sm="4">
          <div class="form-group">
            <label class="form__label">{{
              $lang.opportunity.filter.max_budget
              }}</label>
            <VueSimpleRangeSlider
                style="width: 300px"
                :min="0"
                :max="1000000"
                v-model="max"
            />
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group" style="margin-top: 30px">
            <CButton
                type="submit"
                size="sm"
                color="primary"
                @click="applyFilter()"
                v-c-tooltip="$lang.common.filter.button.filter"
            >
              {{ $lang.common.filter.button.filter }}
            </CButton>
            &nbsp;
            <CButton
                color="danger"
                size="sm"
                @click="resetFilter()"
                v-c-tooltip="$lang.common.filter.button.cancel"
            >
              {{ $lang.common.filter.button.cancel }}
            </CButton>
          </div>
        </CCol>

      </CRow>
      <div>
        <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
        <highcharts class="hc" :options="visibilityChartOptions" ref="visibility"></highcharts>
        <highcharts class="hc" :options="companyIndustriesChartOptions" ref="companyOpportunities"></highcharts>
        <highcharts class="hc" :options="companyResponseChartOptions" ref="companyResponse"></highcharts>
        <highcharts class="hc" :options="companyReportChartOptions" ref="companyReport"></highcharts>

        <highcharts class="hc" :options="industriesChartOptions" ref="industries"></highcharts>

        <highcharts class="hc" :options="servicesChartOptions" ref="services"></highcharts>
        <highcharts class="hc" :options="skillsChartOptions" ref="skills"></highcharts>

        <highcharts class="hc" :options="citiesChartOptions" ref="cities"></highcharts>
        <highcharts class="hc" :options="minBudgetChartOptions" ref="minBudget"></highcharts>
        <highcharts class="hc" :options="maxBudgetChartOptions" ref="maxBudget"></highcharts>
        <highcharts class="hc" :options="multipleResourceMinBudget" ref="multipleResourceMin"></highcharts>
        <highcharts class="hc" :options="multipleResourceMaxBudget" ref="multipleResourceMax"></highcharts>
        <highcharts class="hc" :options="durationChartOptions" ref="duration"></highcharts>
        <highcharts class="hc" :options="remoteWorkChartOptions" ref="remoteWork"></highcharts>
      </div>
      <CRow>
        <CCol md="6">
          <highcharts class="hc" :options="singleExperienceChartOptions" ref="singleExperience"></highcharts>
        </CCol>
        <CCol md="6">
          <highcharts class="hc" :options="multipleExperienceChartOptions" ref="multipleExperience"></highcharts>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<style scoped>
.slot {
  background-color: #3c5de8;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: white;
}
</style>
<script>


import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";

import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);
import exporting from "highcharts/modules/exporting";

exporting(Highcharts);
import exportDataHC from "highcharts/modules/export-data";


exportDataHC(Highcharts);

Vue.use(HighchartsVue)

import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
//import apexchart from "vue-apexcharts";
//import {Chart} from 'highcharts-vue'

import {
  country,
  currencytype,
  duration, experience,
  industry,
  opportunity, opportunityTypes,
  priority, reportsOpportunity,
  resourcetype,
  skills, state,
  users
} from "../../store/url";


Vue.use(ServerTable, {}, false);
export default {
  name: "OpportunityFilter",
  mixins: [Mixin],
  components: {
    // apexchart,
    VueSimpleRangeSlider,
    DateRangePicker
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6)
    return {
      self: this,
      chartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Opportunities, Responses, Reports'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      companyIndustriesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Company / Individual Opportunity Resources'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      companyResponseChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Company Received Response'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      companyReportChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Company Received Report'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      skillsChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Opportunity Skills'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      servicesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Opportunity Services'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      industriesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Opportunity Industry'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      citiesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Cities'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      minBudgetChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Fixed, Single Opportunity Resource Minimum Budget'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      maxBudgetChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Fixed, Single Opportunity Resource Maximum Budget'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      multipleResourceMinBudget: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Multiple Opportunity Resource Minimum Budget'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      multipleResourceMaxBudget: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Multiple Opportunity Resource Maximum Budget'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },

      durationChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Duration of Opportunities'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      remoteWorkChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Remote Work'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      visibilityChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Visibility'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },

      singleExperienceChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Single Opportunity Resources Experience List'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },

      multipleExperienceChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Multiple Opportunity Resources Experience List'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },

      direction: 'ltr',
      format: 'mm/dd/yyyy',
      singleDatePicker: 'range',
      min: [0, 1000000],
      max: [0, 1000000],
      number: 1000,
      appendToBody: true,
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1,
      showWeekNumbers: true,
      showDropdowns: true,
      autoApply: true,
      timePicker: false,
      timePicker24Hour: false,
      dateRange: {startDate, endDate},
      industryOptions: [],
      services: [],
      durationOptions: [],
      priorityOptions: [],
      workTypeOptions: [],
      skillsOptions: [],
      CountryOptions: [],
      StateOptions: [],
      typesOptions: [],
      currencyOptions: [],
      selectedSkills: [],
      userOptions: [],
      remoteWorkOptions: [],
      experienceOptions: [],
      module: opportunity,
      moduleIndustry: industry,
      moduleResource: resourcetype,
      moduleDuration: duration,
      moduleCurrency: currencytype,
      modulePriority: priority,
      moduleSkills: skills,
      countryModule: country,
      stateModule: state,
      moduleUser: users,
      moduleOpportunityTypes: opportunityTypes,
      moduleReportsOpportunity: reportsOpportunity,
      moduleExperience: experience,
      opportunity: {
        industry: "",
        services: "",
        duration: "",
        skills: "",
        country: "",
        types: "",
        state: "",
        remoteWork: "",
        experience: "",
        currency: {
          label: '$ (United States-US )',
          value: '233'
        },
        min_budget_range: "0",
        max_budget_range: "0"
      },
    };
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value)

      return dt.getDate()
    },
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },
  created() {
    this.loadIndustry();
    // this.durationList();
    this.countryList();
    this.loadTypes();
    this.loadExperience();

  },
  methods: {
    getSubIndustry() {
      let self = this;
      if (self.opportunity.industry.value > 0) {
        const postData = {industry_id: self.opportunity.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.services = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.services.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
        this.skillsList(self.opportunity.industry.value);
      }
    },

    loadIndustry() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
        response.data.data.map(function (value, key) {
          if (value.industryId === "") {
            self.industryOptions.push({value: value.id, label: value.industry});
          }
        });
      });
    },
    skillsList(industry_id) {
      let self = this;
      const postData = {industry_id: industry_id};
      axios.post(this.listUrlApi(this.moduleSkills), postData).then((response) => {
        self.skillsOptions = [];
        response.data.data.map(function (value, key) {
          self.skillsOptions.push({value: value.id, label: value.title});
        });
      });
    },
    countryList() {
      let self = this;
      axios.get(this.listUrlApi(this.countryModule)).then((response) => {
        response.data.data.map(function (value, key) {
          self.CountryOptions.push({value: value.id, label: value.name});
          self.currencyOptions.push(
              {
                value: value.id,
                label: value.currencySymbol + ' (' + value.name + '-' + value.countryCode + ' )'
              });
        });
      });
    },
    loadState() {
      let self = this;
      const postData = {countryId: self.opportunity.country};
      self.opportunity.state = '';
      axios
          .post(this.filterUrApi(this.stateModule), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadTypes() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleOpportunityTypes)).then((response) => {
        response.data.data.map(function (value, key) {
          self.typesOptions.push({value: value.id, label: value.title});
        });
      });
    },
    loadExperience() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleExperience)).then((response) => {
        response.data.data.map(function (value, key) {
          self.experienceOptions.push({value: value.id, label: value.title});
        });
      });
    },

    loadOpportunites(reqObj) {
      let self = this;
      axios.post(this.listUrlApi(this.moduleReportsOpportunity), reqObj)
          .then((response) => {
            store.commit("showLoader", false);
            self.chartOptions.series = response.data.data;
            //self.drillChartOptions.series = response.data.data;
          });
    },
    registerReport(reqObj) {
      this.loadOpportunites(reqObj);
      this.drillSkills(reqObj);
      this.drillServices(reqObj);
      this.drillIndustries(reqObj);
      this.topCompaniesOpportunities(reqObj);
      this.topReceivedResponse(reqObj);
      this.topReceivedReport(reqObj);
      this.topCountriesCity(reqObj);
      this.opportunityFromMinBudget(reqObj);
      this.opportunityFromMaxBudget(reqObj);
      this.opportunityDuration(reqObj);
      this.visibilities(reqObj);
      this.remoteWork(reqObj);
      this.opportunitySingleExperiences(reqObj);
      this.opportunityMultipleExperiences(reqObj);
      this.opportunityMultipleResourceMinBudget(reqObj);
      this.opportunityMultipleResourceMaxBudget(reqObj);
    },
    drillSkills(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/skills', reqObj)
          .then((response) => {

            self.skillsChartOptions.series = response.data.data ?? [];
          });
    },
    drillServices(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/services', reqObj)
          .then((response) => {

            self.servicesChartOptions.series = response.data.data ?? [];
          });
    },
    drillIndustries(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/industries', reqObj)
          .then((response) => {

            self.industriesChartOptions.series = response.data.data ?? [];
          });
    },
    topCompaniesOpportunities(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/top-companies-opportunities', reqObj)
          .then((response) => {

            self.companyIndustriesChartOptions.series = response.data.data ?? [];
          });
    },
    topReceivedResponse(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/top-received-response', reqObj)
          .then((response) => {
            self.companyResponseChartOptions.series = response.data.data ?? [];
          });
    },
    topReceivedReport(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/top-received-report', reqObj)
          .then((response) => {
            self.companyReportChartOptions.series = response.data.data ?? [];
          });
    },
    topCountriesCity(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/top-countries-city', reqObj)
          .then((response) => {
            self.citiesChartOptions.series = response.data.data ?? [];
          });
    },
    opportunityDuration(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/duration', reqObj)
          .then((response) => {
            self.durationChartOptions.series = response.data.data ?? [];
          });
    },
    visibilities(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/visibilities', reqObj)
          .then((response) => {
            self.visibilityChartOptions.series = response.data.data ?? [];
          });
    },
    remoteWork(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/remote-work', reqObj)
          .then((response) => {
            self.remoteWorkChartOptions.series = response.data.data ?? [];
          });
    },
    opportunitySingleExperiences(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/single/experiences', reqObj)
          .then((response) => {
            self.singleExperienceChartOptions.series = response.data.data ?? [];
          });
    },
    opportunityMultipleExperiences(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/multiple/experiences', reqObj)
          .then((response) => {
            self.multipleExperienceChartOptions.series = response.data.data ?? [];
          });
    },
    /*Fixed, Single Min and Max Budget */
    opportunityFromMinBudget(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/single/from-min-budget', reqObj)
          .then((response) => {
            self.minBudgetChartOptions.series = response.data.data ?? [];
          });
    },
    opportunityFromMaxBudget(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/single/from-max-budget', reqObj)
          .then((response) => {
            self.maxBudgetChartOptions.series = response.data.data ?? [];
          });
    },
    /*Multiple Resources Min and Max Budget */
    opportunityMultipleResourceMinBudget(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/multiple/from-min-budget', reqObj)
          .then((response) => {
            self.multipleResourceMinBudget.series = response.data.data ?? [];
          });
    },
    opportunityMultipleResourceMaxBudget(reqObj) {
      let self = this;
      axios.post('/reports/opportunities/multiple/from-max-budget', reqObj)
          .then((response) => {
            self.multipleResourceMaxBudget.series = response.data.data ?? [];
          });
    },

    applyFilter() {
      let self = this;
      let industry = "";
      let services = "";
      let duration = "";
      let currency = "";
      let skills, country, state, types, remoteWork, experience = "";
      self.selectedSkills = [];
      self.selectedServices = [];
      ///alert(this.opportunity.currency.value);
      industry = this.checkNullValue(this.opportunity.industry) ? this.opportunity.industry.value : '';
      services = this.checkNullValue(this.opportunity.services) ? this.opportunity.services.value : '';
      duration = this.checkNullValue(this.opportunity.duration) ? this.opportunity.duration : '';
      remoteWork = this.checkNullValue(this.opportunity.remoteWork) ? this.opportunity.remoteWork.value : '';
      experience = this.checkNullValue(this.opportunity.experience) ? this.opportunity.experience.value : '';
      currency = this.checkNullValue(this.opportunity.currency) ? this.opportunity.currency.value : '';
      country = this.checkNullValue(this.opportunity.country) ? this.opportunity.country.value : '';
      state = this.checkNullValue(this.opportunity.state) ? this.opportunity.state.value : '';
      types = this.checkNullValue(this.opportunity.types) ? this.opportunity.types.value : '';
      if (this.checkNullValue(this.opportunity.skills)) {
        skills = self.opportunity.skills;
        skills.map(function (value, key) {
          self.selectedSkills.push(value.value);
        });
      }
      if (this.checkNullValue(this.opportunity.services)) {
        services = self.opportunity.services;
        services.map(function (value, key) {
          self.selectedServices.push(value.value);
        });
      }
      var reqObj = {
        industry: industry,
        services: self.selectedServices,
        duration: duration,
        currency: currency,
        dateRange: this.dateRange,
        min: this.min,
        max: this.max,
        country: country,
        state: state,
        types: types,
        skills: self.selectedSkills,
        remoteWork: remoteWork,
        experience: experience,
      };
      store.commit("showLoader", true);
      // console.log(reqObj);
     // this.loadOpportunites(reqObj);
      this.filterReport(reqObj);
    },
    resetFilter() {
      let self = this;
      this.$data.opportunity = [];
      self.services = []
      self.skillsOptions = [];
      self.opportunity.currency = {
        label: '$ (United States-US )',
        value: '233'
      }
    },

    filterReport(params) {
      if (!params) {
        let self = this;
        self.currency = this.checkNullValue(this.opportunity.currency) ? this.opportunity.currency.value : '';
        params = {dateRange: this.dateRange, currency: self.currency};
      }
      this.registerReport(params);
      store.commit("showLoader", false);
    }

  },

  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    this.filterReport();

    self.remoteWorkOptions.push(
        {value: "Yes", label: "Yes"},
        {value: "No", label: "No"}
    );
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

#container {
  height: 400px;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

</style>
